export interface OrganizationScope {
  organizationId: string
}
export interface LocalityScope {
  localityId: string
}

export interface EntityScope {
  entityId: string
  entityType: string
}

export interface CountryScope {
  countryId: string
}

export interface RegionScope {
  regionId: string
}

export interface ApplicantsScope {
  retainmentToken: string
}

export type Scope = OrganizationScope & LocalityScope & RegionScope
export type ScopeTypes = 'organizations' | 'regions' | 'locations'

export interface CandidateScope {
  candidateId: string
}

export interface Resource<T = object> {
  id: string
  type: string
  attributes: T
}

export interface ListQueryParams {
  sort?: string
  page?: {
    number?: number
    size?: number
  }
  search?: string
  filter?: Record<string, string[] | string>
}

export interface Pagination {
  pages: number
  total: number
}

export interface Meta {
  pagination: Pagination
  version: string
  copyright: string
}

export interface ResponseBody<T> {
  data: T[]
  meta: Meta
}

export interface ResponseError {
  path: string
  reasons: string[]
}

export interface ResponseErrorBody {
  errors: Error[]
}

export const pagination = {
  current: 0,
  records: 0,
  last: 0,
  prev: null,
  next: null,
}

type ObjKeyof<T> = T extends object ? keyof T : never
type KeyofKeyof<T> = ObjKeyof<T> | { [K in keyof T]: ObjKeyof<T[K]> }[keyof T]
type StripNever<T> = Pick<T, { [K in keyof T]: [T[K]] extends [never] ? never : K }[keyof T]>
type Lookup<T, K> = T extends any ? (K extends keyof T ? T[K] : never) : never
export type SimpleFlatten<T> = T extends object
  ? StripNever<{
      [K in KeyofKeyof<T>]:
        | Exclude<K extends keyof T ? T[K] : never, object>
        | { [P in keyof T]: Lookup<T[P], K> }[keyof T]
    }>
  : T
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
