import React, { createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Scope } from 'store/store.types'
import { SettingsAttributes } from 'store/settings/settings.api'
import { useGetOneOrganizationQuery } from 'store/organizations/organizations.api'

const OrgSettings = createContext<SettingsAttributes | any>({})

interface OrgSettingsProviderProps {
  children: React.ReactNode
}

export const OrgSettingsProvider = (props: OrgSettingsProviderProps) => {
  const { organizationId } = useParams<Scope>()

  const { data: organization } = useGetOneOrganizationQuery(organizationId)
  const ssoEnabled = organization?.data.configuration.single_sign_on?.enabled === true

  const orgSettings = {
    ssoEnabled,
  }

  return <OrgSettings.Provider value={orgSettings}>{props.children}</OrgSettings.Provider>
}

export function useOrgSettings() {
  return useContext(OrgSettings)
}
