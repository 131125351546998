import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { DialogTitle } from 'shared/components'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Controller, useForm } from 'react-hook-form'
import { addSnack } from 'store/snacks'
import { useUpdateApplicantMutation } from 'store/applicants/applicants.api'
import { useAllOpeningsQuery } from 'store/openings/opening.api'
import { useStyles } from './ConfirmationUpdateDialog.styles'
import { useAppDispatch } from 'shared/hooks/redux'
import { KrowTransition } from 'shared/components'

function ConfirmationUpdateDialog(props) {
  const { open, onClose, onUpdate, title, description, applicant, localityId, selectedStatus } =
    props

  const classes = useStyles()

  const dispatch = useAppDispatch()

  const { data = { data: [] } } = useAllOpeningsQuery({ localityId: localityId })

  const options = React.useMemo(() => {
    return data?.data?.map((opening) => ({ name: opening.position.name, value: opening.id })) || []
  }, [data?.data])

  const [selectedOpening, setSelectedOpening] = useState(null)

  const { control, formState, handleSubmit } = useForm()

  const statusActionAllowedToModifyOpenings =
    selectedStatus?.action === 'hire' || selectedStatus?.action === 'keep_warm'

  const [update] = useUpdateApplicantMutation()

  const onSubmit = handleSubmit(async () => {
    try {
      if (selectedOpening) {
        await update({ id: applicant.id, opening_id: selectedOpening.value }).unwrap()
      }
      onUpdate(selectedStatus)
    } catch (e) {
      dispatch(addSnack({ message: 'Unable to update candidate status.', severity: 'error' }))
    }
  })
  const stateTextToRender = selectedStatus.action ? selectedStatus.action.replace(/_/g, ' ') : ''

  useEffect(() => {
    if (open) {
      setSelectedOpening(
        options?.find((opening) => opening.value === applicant?.opening?.id) || null
      )
    }
  }, [open, options, applicant])

  return (
    <Dialog
      data-cy="confirmation-dialog"
      open={open}
      TransitionComponent={KrowTransition}
      keepMounted
      onClose={onClose}
      aria-labelledby="confirmation-title"
      aria-describedby="confirmation_dialog"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Typography variant="h3" id="organization-title">
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <Typography className={classes.dialogDescription}>{description}</Typography>
        {props.children}
        <Grid className={classes.grid} container>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Full Name
            </Typography>
            <Typography lineHeight="27px">{applicant?.full_name}</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Selected Status
            </Typography>
            <Typography lineHeight="27px" className={classes.selectedStatus}>
              {stateTextToRender}
            </Typography>
          </Grid>
        </Grid>
        {statusActionAllowedToModifyOpenings && (
          <Box className={classes.statusDropdown}>
            <Controller
              name="opening"
              control={control}
              render={({ field, fieldState }) => {
                const { ...rest } = field
                return (
                  <Autocomplete
                    {...rest}
                    onChange={(e, option) => setSelectedOpening(option)}
                    id="opening_id"
                    data-cy="opening"
                    options={options}
                    disableClearable
                    isOptionEqualToValue={(option, selected) => option.value === selected.value}
                    getOptionLabel={(option) => option.name}
                    value={selectedOpening}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Opening"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                        error={!!fieldState.error?.message}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )
              }}
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          py: 2,
        }}
      >
        <Button autoFocus color="primary" variant="outlined" onClick={onClose} size="small">
          Cancel
        </Button>
        <LoadingButton
          loading={formState.isSubmitting}
          onClick={onSubmit}
          variant="contained"
          color="primary"
          size="small"
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationUpdateDialog
