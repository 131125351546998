import React from 'react'
import { useAppDispatch } from 'shared/hooks/redux'
import Tooltip from '@mui/material/Tooltip'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Box from '@mui/material/Box'
import { useAppSelector } from 'shared/hooks/redux'
import { selectBuilderStep } from 'store/selectors'
import { changeBuilderStep, BuilderStepOptions } from 'store/questionnaires'

interface BuilderToolbarProps {}

function BuilderToolbar(props: BuilderToolbarProps) {
  const dispatch = useAppDispatch()
  const activeBuilderStep = useAppSelector(selectBuilderStep)
  const isQuestionnaireValid = useAppSelector((state) => state.questionnaires.isValid)

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextBuilderStep: BuilderStepOptions
  ) => {
    if (nextBuilderStep !== null) {
      dispatch(changeBuilderStep(nextBuilderStep))
    }
  }

  React.useEffect(() => {
    return () => {
      dispatch(changeBuilderStep('builder'))
    }
  }, [dispatch])

  return (
    <Box justifySelf="center" display="flex" alignItems="center" mr={4}>
      <Tooltip
        disableTouchListener={isQuestionnaireValid}
        disableInteractive={isQuestionnaireValid}
        disableHoverListener={isQuestionnaireValid}
        disableFocusListener={isQuestionnaireValid}
        title="Fix all errors to enable"
      >
        <ToggleButtonGroup
          color="primary"
          value={activeBuilderStep}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton disabled={!isQuestionnaireValid} value="builder">
            Builder
          </ToggleButton>
          <ToggleButton disabled={!isQuestionnaireValid} value="logic">
            Logic
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </Box>
  )
}

export default BuilderToolbar
